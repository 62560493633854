<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit Food Merchant Category</h4>
    </vs-row>

    <vs-row vs-w="6">
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              label="Category Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2" :disabled="loading"
                >Submit</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";
// Store Module
import foodMerchantCategoryStore from "@/store/food-merchant-category";

export default {
  name: "FoodMerchantCategoryEdit",

  metaInfo: {
    title: "Edit Food Merchant Category Edit",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    vSelect,
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },
  },

  data: () => ({
    payload: {
      name: "",
    },
    featureCategoryOptions: [],
    loading: false,
  }),

  methods: {
    getFeatureCategories() {
      axios
        .get("/feature-category")
        .then(({ data: res }) => {
          this.featureCategoryOptions = res.data.map((category) => {
            return {
              id: category.id,
              name: category.name,
            };
          });
        })
        .catch((err) => this.$catchErrorResponse(err));
    },
    async getData() {
      const response = await this.$store.dispatch(
        "foodMerchantCategory/fetchFoodMerchantCategory",
        this.decryptedID
      );
      this.payload.name = response.message.name;
      this.payload.feature_category_id = response.message.feature_category.id;
      this.$vs.loading.close();
    },

    async storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.append("_method", "PUT");
      this.loading = true;
      const response = await this.$store.dispatch(
        "foodMerchantCategory/updateFoodMerchantCategory",
        {
          id: this.decryptedID,
          body: formData,
        }
      );
      this.loading = false;
      if (!response.success) {
        this.$catchErrorResponse(response.message);
        return;
      }

      this.$vs.notify({
        title: "Successful",
        text: response.msg_status,
        iconPack: "feather",
        icon: "icon-check-circle",
        color: "success",
      });

      this.$router.push("/food-merchant-category");
    },
  },

  created() {
    if (!foodMerchantCategoryStore.isRegistered) {
      this.$store.registerModule(
        "foodMerchantCategory",
        foodMerchantCategoryStore
      );
      foodMerchantCategoryStore.isRegistered = true;
    }

    this.getFeatureCategories();
    this.getData();
  },
};
</script>

<style></style>
